import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    padding: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '400px',
  },
  label: {
    marginBottom: '5px',
  },
  input: {
    marginBottom: '15px',
    padding: '10px',
    fontSize: '16px',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  registerLink: {
    marginLeft: '30px',
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

const Login = () => {
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [secret, setSecret] = useState('');
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault(); 
  
    try {
      const response = await axios.post('http://localhost:5004/server', {
        username,
        password,
        secret,
      });
  
      if (response.status === 200) { 
        alert('Admin data verified successfully');
        localStorage.setItem('isAuthenticated', 'true');
        navigate('/admin');
      } else {
        alert('Invalid credentials or server error');
      }
    } catch (error) {
      // Improved error handling
      if (error.response) {
        alert(error.response.data || 'Server error');
      } else if (error.request) {
        alert('No response from server');
      } else {
        alert('Error setting up request: ' + error.message);
      }
    }
  };
  
  // const handleLogin = async (e) => {
  //   e.preventDefault(); 
  
  //   try {
  //     const response = await axios.post('http://localhost:5004/server', {
  //       email,
  //       password,
  //       secret,
  //     });
  
  //     if (response.status === 200) { 
  //       alert('Admin data verified successfully');
  //       localStorage.setItem('isAuthenticated', 'true');
  //       navigate('/admin');
  //     } else {
  //       alert('Invalid credentials or server error');
  //     }
  //   } catch (error) {
  //     // Improved error handling
  //     if (error.response) {
  //       // Server responded with a status other than 200 range
  //       alert(error.response.data || 'Server error');
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       alert('No response from server');
  //     } else {
  //       // Something happened in setting up the request that triggered an Error
  //       alert('Error setting up request: ' + error.message);
  //     }
  //   }
  // };

  return (
    <div style={styles.container}>
      <h1>Login Page</h1>
      <form style={styles.form} onSubmit={handleLogin}>
        <label style={styles.label} htmlFor='email'>Email</label>
        <input
          style={styles.input}
          placeholder='Enter your email'
          name='email'
          id='email'
          required
          type='email'
          value={username}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label style={styles.label} htmlFor='password'>Password</label>
        <input
          style={styles.input}
          placeholder='Enter your password'
          name='password'
          id='password' required
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label style={styles.label} htmlFor='secret'>Secret Key</label>
        <input
          style={styles.input}
          placeholder='Enter your secret key'
          name='secret'
          id='secret'
          value={secret} required
          onChange={(e) => setSecret(e.target.value)}
        />
        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
          <a style={styles.registerLink} href='/register'>Register</a>
        </div> */}
        <button style={styles.button} type='submit'>Login</button>
      </form>
    </div>
  );
};

export default Login;

 