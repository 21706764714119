 
//give this original

//   import React from 'react';
//   import styled, { createGlobalStyle } from 'styled-components';
//   import bgRed from '../assets/backt.jpg';
  
//   import Header from './Header';
//   import Footer from './Footer';
//   // Import the Contacts component

//   // Global styles
//   const GlobalStyle = createGlobalStyle`
//     * {
//       box-sizing: border-box;
//       margin: 0;
//       padding: 0;
//     }
//     body {
//       font-family: Arial, sans-serif;
//     }
//   `;

//   // Styled components
//   const MainContainer = styled.div`
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     min-height: 100vh;  
//     background: none;
//     overflow: hidden;
//     padding-top: 60px; // Space for the fixed header

//     @media (max-width: 768px) {
//       padding-top: 50px;
//       height: auto; // Adjust height for smaller screens
//     }
//   `;

//   const BackgroundImage = styled.div`
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-image: ${(props) => `url(${props.image})`};
//     background-size: cover;
//     background-position: center;
//     z-index: -1;

//     @media (max-width: 768px) {
//       width: 100%;
//       height: auto; // Ensure the background image covers the entire viewport
//     }
//   `;

//   const ContentContainer = styled.div`
//     position: relative;
//     z-index: 1;
//     text-align: center;
//     padding: 20px;
//   `;

//   const Title = styled.div`
//     font-size: 3.5rem;
//     color: #34495e;
//     margin-bottom: 20px;

//     @media (max-width: 768px) {
//       font-size: 2rem;
//     }
//   `;

//   const Subtitle = styled.div`
//     font-size: 1.25rem;
//     color: #7f8c8d;

//     @media (max-width: 768px) {
//       font-size: 1rem;
//     }
//   `;

//   // MainContent component
//   const MainContent = () => {
//     return (
//       <>
//         <GlobalStyle />
//         <Header /> 
//         <MainContainer> 
//           <BackgroundImage image={bgRed} alt="Red background" /> 
//           {/* <BackgroundImage image={bgYellow} alt="Yellow background" />  */}
//           <ContentContainer>
//             <Title>Sikkim Lotteries..</Title>
//             <Subtitle>
//             We assure the security of your money and <br />
//             the value of your trust...
//             </Subtitle>
//           </ContentContainer>
// <Footer/>
    
//         </MainContainer> 
//       </>
//     );
//   };

//   export default MainContent;




import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import bgRed from '../assets/backt.jpg';
import Header from './Header';
import Footer from './Footer';

// Global styles
const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: Arial, sans-serif;
  }
`;

// Styled components
const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;  
  overflow: hidden;
  padding-top: 60px; // Space for the fixed header

  @media (max-width: 768px) {
    padding-top: 50px;
    height: auto; // Allow auto height on smaller screens
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover; // Cover the entire area
  background-position: center; // Center the image
  z-index: -1;

  /* Ensure image covers the full backdrop on all devices */
  @media (max-width: 768px) {
    height: 100%;
    min-height: 100vh; // Ensure it covers the full viewport height on mobile
  }
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px;
  color: white; // Adjust text color for better visibility on dark background

  @media (max-width: 768px) {
    padding: 10px; // Reduce padding on mobile to fit smaller screens
  }
`;

const Title = styled.div`
  font-size: 3.5rem;
  color: #34495e;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2.5rem; // Slightly larger text for visibility
  }
`;

const Subtitle = styled.div`
  font-size: 1.25rem;
  color: #7f8c8d;

  @media (max-width: 768px) {
    font-size: 1.125rem; // Font size adjustment for mobile
  }
`;

// MainContent component
const MainContent = () => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <MainContainer> 
        <BackgroundImage image={bgRed} alt="Red background" />
        <ContentContainer>
          <Title>Sikkim Lotteries..</Title>
          <Subtitle>
            We assure the security of your money and <br />
            the value of your trust...
          </Subtitle>
        </ContentContainer>
        <Footer />
      </MainContainer>
    </>
  );
};

export default MainContent;