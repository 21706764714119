import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    padding: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '400px',
  },
  label: {
    marginBottom: '5px',
  },
  input: {
    marginBottom: '15px',
    padding: '10px',
    fontSize: '16px',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  '@media (min-width: 768px)': {
    form: {
      maxWidth: '500px',
    },
  },
};

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [secret, setSecret] = useState('');
  const navigate = useNavigate();

 

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5004/register', {
        email,
        password,
        secret,
      });
      alert(response.data);
      navigate('/login');
    } catch (error) {
      alert(error.response ? error.response.data : 'Server error');
    }
  };

  return (
    <div style={styles.container}>
      <h1>Register Page</h1> 
      <form style={styles.form} onSubmit={handleRegister}>
        <label style={styles.label} htmlFor='registerEmail'>Register Email</label>
        <input
          style={styles.input}
          placeholder='Enter your email'
          name='registerEmail'
          id='registerEmail'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label style={styles.label} htmlFor='registerPassword'>Register Password</label>
        <input
          style={styles.input}
          placeholder='Enter your password'
          name='registerPassword'
          id='registerPassword'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label style={styles.label} htmlFor='registerSecret'>Register Secret Key</label>
        <input
          style={styles.input}
          placeholder='Enter your secret key'
          name='registerSecret'
          id='registerSecret'
          value={secret}
          onChange={(e) => setSecret(e.target.value)}
        />
        <button style={styles.button} type='submit'>Register</button>
      </form>
    </div>
  );
};

export default Register;
